<template>
  <div>
    <div class="content__party content__party--start">
      <a :href="`/admin/company/${$route.params.id}/discount/create`" class="button button--light button--no-indent button--circle">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить скидку</span>
      </a>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из
          <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table :data="table.cards" :columns="table.columns" :options="table.options" ref="discountTable"
                    class="table-default">
      <div slot="date_start" slot-scope="props">
        с {{ props.row.date_start }} <span v-if="props.row.date_end">по {{ props.row.date_end }}</span>
      </div>
      <div slot="type" slot-scope="props">
        {{ props.row.type === 1 ? 'Скидка' : 'Спецпредложение' }}
      </div>
      <div slot="tooltip" slot-scope="props">
        <v-popover
          placement="left-start"
        >
          <button class="tooltip-target b3">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z"
                fill="#2B93E7"/>
              <path
                d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z"
                fill="#2B93E7"/>
              <path
                d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z"
                fill="#2B93E7"/>
            </svg>
          </button>
          <template slot="popover">
            <ul class="tooltip__list">
              <li class="tooltip__item">
                <img src="@/assets/img/edit-icon.svg" alt="Edit icon">
                <a :href="`/admin/company/${$route.params.id}/discount/${props.row.id}`" class="tooltip__btn">Редактировать</a>
              </li>
              <li class="tooltip__item">
                <img src="@/assets/img/trash-icon.svg" alt="Delete icon">
                <button @click="deleteDiscount($route.params.id, props.row.id)" class="tooltip__btn">Удалить</button>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';

export default {
  name: 'Discount',
  components: {dropdown},
  data() {
    return {
      table: {
        cards: [],
        columns: ['id', 'type', 'title', 'date_start', 'tooltip'],
        options: {
          headings: {
            id: 'ID',
            type: 'Тип',
            title: 'Заголовок',
            date_start: 'Срок действия',
            tooltip: ''
          },
          pagination: {show: false},
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback() {
            return 'table-default__row';
          },
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: {name: this.$store.state.admin.tableLimit},
      limitOptions: [
        {name: 20},
        {name: 40},
        {name: 60},
        {name: 80},
        {name: 100},
      ],
    };
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchDiscount();
  },
  beforeDestroy() {
    this.updateLimiter({name: 20});
  },
  methods: {
    fetchDiscount() {
      this.$store.dispatch('admin/discounts/GET_LIST', this.$route.params.id)
        .then(response => {
          if (response.status >= 400) {
            this.$notify({
              type: 'warn',
              title: 'Внимание!',
              text: 'Ошибка получения данных'
            });
          }
          this.table.cards = response.data.results;
          this.pagination.pages = response.data.pages;
          this.pagination.count = response.data.count;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    deleteDiscount(id, discount) {
      this.$store.dispatch('admin/discounts/DELETE_DATA', {id, discount})
        .then(() => {
          document.body.click();
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Скидка успешно удалена'
          });
          this.fetchDiscount();
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Внимание!',
            text: 'Ошибка удаления скидки'
          });
        });
    },
    updateLimiter(data) {
      this.$store.commit('admin/changeTableLimit', data.name);
      this.$refs.discountTable.setLimit(this.$store.state.admin.tableLimit);
      this.fetchDiscount(1, this.$store.state.admin.tableLimit);
    },
    clickPaginationCallback(page) {
      this.fetchDiscount(page, this.$store.state.admin.tableLimit);
    },
    returnStart() {
      return (this.pagination.page * this.table.cards.length) - (this.table.cards.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.table.cards.length - 1;
    },
  }
};
</script>